/* eslint-disable */
import React, { PureComponent } from 'react';

import Helmet from 'react-helmet';

import seo from 'tools/seo';

import Loading from 'components/Loading';

type Props = {};

class ThankYou extends PureComponent<Props> {
  state = { loaded: false };

  onLoad = () => {
    this.setState({ loaded: true });
  };

  render() {
    const { loaded } = this.state;
    // https://magic.reactjs.net/htmltojsx.htm
    return (
      <>
        <Helmet>
          {seo.title('Thank You - Flowfo')}
          {seo.description('Your choice has been saved.')}
        </Helmet>
        {!loaded && <Loading />}
        <div className={`Page-Webflow ${loaded || 'd-none'}`}>
          {/* DEBUT HTML WEBFLOW Thank You in english */}

          {/* This site was created in Webflow. http://www.webflow.com */}
          {/* Last Published: Fri Jun 11 2021 17:25:39 GMT+0000 (Coordinated Universal Time) */}
          <meta charSet="utf-8" />
          <title>Thank You - ENGLISH</title>
          <meta content="Thank You - ENGLISH" property="og:title" />
          <meta content="Thank You - ENGLISH" property="twitter:title" />
          <meta content="Webflow" name="generator" />
          <link
            href="https://uploads-ssl.webflow.com/5d285eb314c23d4cf7cb9f29/css/prices-flowfo.webflow.1661733b4.css"
            onLoad={this.onLoad}
            rel="stylesheet"
            type="text/css"
          />
          {/*[if lt IE 9]><![endif]*/}
          <link href="https://uploads-ssl.webflow.com/img/favicon.ico" rel="shortcut icon" type="image/x-icon" />
          <link href="https://uploads-ssl.webflow.com/img/webclip.png" rel="apple-touch-icon" />
          <div>
            <div className="divdeslangages" />
            <div className="div-block" />
            <div className="divthankyou">
              <h1 className="webflowthankyou">Thank you!!</h1>
              <h1 className="webflowthankyou">In 2 min, you'll receive your 1st DareX.</h1>
              <h2 className="webflowheading2 violet">Check your spam ; &#41;</h2>
            </div>
            <div className="divwithcontent" />
            <div className="divendblank" />
          </div>
          {/*[if lte IE 9]><![endif]*/}

          {/* FIN HTML WEBFLOW ThankYou in english */}
        </div>
      </>
    );
  }
}

export default ThankYou;
